import ApolloClient from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { athenaUrl } from '../backend'

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = (token, removeToken) => {
  const authLink = createHttpLink({
    uri: `${athenaUrl}/admin`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    },
  })

  const logoutLink = onError(({ networkError }) => {
    if (networkError && networkError.statusCode && networkError.statusCode) {
      removeToken()
    }
  })

  return new ApolloClient({
    link: logoutLink.concat(authLink),
    cache: new InMemoryCache(),
    defaultOptions,
  })
}

export default client
