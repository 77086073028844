import React from 'react'

export default function Icon({
  name,
  ...props
}: {
  name: string
  style?: any
}) {
  return <i className={`far fa-${name}`} {...props} />
}
