import React, { useState, useContext, useEffect, createContext } from 'react'

export enum Mode {
  DARK = 'dark-mode',
  LIGHT = 'light-mode',
}

export interface IModeContext {
  mode: Mode
  setMode: (mode: Mode) => void
}

let ModeContext = createContext<IModeContext | null>(null)

export function ModeProvider(props: any) {
  let [mode, setMode] = useState(() => {
    return localStorage.getItem('spaceIQ_mode') || Mode.DARK
  })

  useEffect(() => {
    document.body.classList.remove(Mode.LIGHT)
    document.body.classList.remove(Mode.DARK)
    document.body.classList.add(mode)
    localStorage.setItem('spaceIQ_mode', mode)
  }, [mode])

  return <ModeContext.Provider value={{ mode, setMode }} {...props} />
}

export function useMode() {
  let modeContext = useContext(ModeContext)
  return modeContext as IModeContext
}
