import React from 'react'
import Icon from '../UI/FAIcon'
import styled from 'styled-components'
import AppBar from '../AppBar'
import { RouteComponentProps } from '@reach/router'

interface IProps {
  setAccessToken: (s: string) => void
}

export default function UnderConstruction({
  setAccessToken,
}: IProps & RouteComponentProps) {
  return (
    <>
      <AppBar title="Dashboard" setAccessToken={setAccessToken} />
      <Main>
        <IconContainer>
          <Icon name="truck-container" style={{ fontSize: 60 }} />
        </IconContainer>
        <p>Under Construction...</p>
      </Main>
    </>
  )
}

const Main = styled.div`
  margin-top: 50px;
  padding: 10px;
  background: #293742;
  overflow: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 30px;
  color: #e1e8ed;
`

const IconContainer = styled.div`
  background: #202b33;
  height: 150px;
  width: 150px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`
