import React, { createContext } from 'react'
import styled from 'styled-components'
import { ApolloProvider } from 'react-apollo'
import clientGen from '../store/apollo'
import getUser from './user'

// import Navigation from './Navigation'
// import Logo from './Logo'

import { RouteComponentProps } from '@reach/router'
interface IProps {
  children: JSX.Element | JSX.Element[]
  token: string
  removeToken: () => void
}

export const UserContext = createContext<string>('')

export default function Authenticated({
  children,
  token,
  removeToken,
}: IProps & RouteComponentProps) {
  const activeClient = clientGen(token, removeToken)

  const user = getUser({ client: activeClient })

  return (
    <>
      {/* <Sidebar>
        <Logo />
        <Navigation />
      </Sidebar> */}
      <Main>
        <UserContext.Provider value={user}>
          <ApolloProvider client={activeClient}>{children}</ApolloProvider>
        </UserContext.Provider>
      </Main>
    </>
  )
}

const Main = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`

// const Sidebar = styled.div`
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   padding: 0 16px;
//   width: 200px;
// `
