import React from 'react'
import {
  Button,
  MenuItem,
  Position,
  Intent,
  Menu,
  Popover,
  Tooltip,
} from '@blueprintjs/core'
import Icon from './UI/FAIcon'

export interface ITools {
  text: string
  onClick: (event: any) => void
  icon?: string
}

export default function CompanyToolSelector({
  buttons = [],
}: {
  buttons?: ITools[]
}) {
  const onlyLogin = buttons.length === 1
  return (
    <Popover
      minimal
      usePortal
      position={Position.BOTTOM_LEFT}
      renderTarget={({ isOpen, ...targetProps }) =>
        renderToolTip(onlyLogin, buttons, targetProps)
      }
      content={
        !onlyLogin ? (
          <Menu>
            {buttons.map((b) => (
              <MenuItem
                text={b.text}
                onClick={b.onClick}
                icon={
                  b.icon ? (
                    <Icon name={b.icon} style={{ marginTop: 3 }} />
                  ) : null
                }
              />
            ))}
          </Menu>
        ) : undefined
      }
    />
  )
}

const renderToolTip = (
  onlyLogin: boolean,
  buttons: any[],
  targetProps: any
) => {
  const b = buttons[0]
  return (
    <Tooltip position="top-left" content={b.text}>
      {onlyLogin ? (
        <Button
          {...targetProps}
          large
          minimal
          onClick={b.onClick}
          className="round"
          style={{
            marginLeft: 10,
            width: 20,
          }}
          rightIcon={
            <Icon
              name="arrow-right"
              style={{ color: 'var(--light-text-color)' }}
            />
          }
        />
      ) : (
        <Button
          {...targetProps}
          large
          minimal
          className="round"
          style={{
            marginLeft: 10,
            width: 20,
          }}
          rightIcon={
            <Icon name="cog" style={{ color: 'var(--light-text-color)' }} />
          }
        />
      )}
    </Tooltip>
  )
}
