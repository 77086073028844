import React, { useContext } from 'react'
import styled from 'styled-components'
import Icon from './UI/FAIcon'
import {
  Popover,
  Button,
  Menu,
  MenuItem,
  MenuDivider,
  Position,
} from '@blueprintjs/core'
import { useMode, Mode } from '../modeContext'
import { UserContext } from './Authenticated'

interface IProps {
  title: string
  children?: JSX.Element | JSX.Element[]
  setAccessToken: (s: string) => void
}

export default ({ title, children, setAccessToken }: IProps) => {
  let { mode, setMode } = useMode()
  let user = useContext(UserContext)

  let initials = user
    .split(' ')
    .map((token) => token[0])
    .join('')

  return (
    <Container>
      <Inner>
        <Section>
          <Title>{title}</Title>
        </Section>
        {children}
        <Section style={{ justifyContent: 'flex-end' }}>
          <Button
            large
            minimal
            className="round"
            icon={
              <Icon
                name={`lightbulb${mode === Mode.DARK ? '' : '-on'}`}
                style={{ color: 'var(--text-color)' }}
              />
            }
            onClick={() => setMode(mode === Mode.DARK ? Mode.LIGHT : Mode.DARK)}
          />
          <Popover
            minimal
            usePortal
            position={Position.BOTTOM_RIGHT}
            renderTarget={({ isOpen, ...targetProps }) => (
              <Button
                style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                large
                minimal
                className="round"
                {...targetProps}>
                <div
                  style={{
                    textTransform: 'uppercase',
                    color: 'var(--text-color)',
                  }}>
                  {initials}
                </div>
              </Button>
            )}
            content={
              <Menu>
                <MenuDivider title={`Hi, ${user}!`} />
                <MenuItem
                  text="Logout"
                  onClick={() => {
                    setAccessToken('')
                    window.localStorage.removeItem('_jX22Dksj&.K')
                  }}
                />
              </Menu>
            }
          />
        </Section>
      </Inner>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 00px;
  right: 0;
  z-index: 1;
  height: 50px;
  color: var(--text-color);
  background: var(--appbar-background-color);
`

let Inner = styled.div`
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.span`
  font-size: 22px;
`

export const Section = styled.div`
  flex: 1 1 30%;
  display: flex;
  position: relative;
`
