import React, { useState } from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import {
  Dialog,
  Tag,
  Classes,
  Button,
  Toaster,
  Intent,
} from '@blueprintjs/core'
import Icon from '../UI/FAIcon'
import { ApolloClient } from 'apollo-boost'
import { IProduct, VoidF } from './index'
import { IManual } from './CompanyPanel'
import { ICompany } from '../CompanySelector'

let productMutation = gql`
  mutation updateCompanyProduct($input: UpdateCompanyProductInput!) {
    updateCompanyProduct(input: $input) {
      companyProduct {
        id
        features
        name
        integrations
        reports
      }
    }
  }
`

interface IMutationPayload {
  updateCompanyProduct: {
    companyProduct: IProduct
  }
}

interface IProps {
  toasterRef: React.RefObject<Toaster>
  isOpen: boolean
  selectedCompany: ICompany | undefined
  currentTier: string
  companyProduct: IProduct
  productCopy: { tiers: string[] }
  manuallyManaged: {
    [name: string]: IManual
  }
  client: ApolloClient<any>
  setContinuing: VoidF<boolean>
  setCurrentTier: VoidF<string>
  setManuallyManaged: VoidF<{ [name: string]: IManual }>
  setCompanyProduct: VoidF<IProduct>
}

export default function ChangesDialog({
  toasterRef,
  isOpen,
  currentTier,
  companyProduct,
  productCopy,
  manuallyManaged,
  client,
  setContinuing,
  setCurrentTier,
  setManuallyManaged,
  setCompanyProduct,
}: IProps) {
  let [mutating, setMutating] = useState<boolean>(false)
  return (
    <Dialog
      isCloseButtonShown={false}
      onClose={() => setContinuing(false)}
      isOpen={isOpen}
      title={''}
      style={{ position: 'relative' }}>
      <Button
        large
        minimal
        className="round"
        style={{ position: 'absolute', top: 5, right: 5 }}
        onClick={() => setContinuing(false)}>
        <Icon name="times" style={{ color: 'var(--light-text-color)' }} />
      </Button>
      <div className={Classes.DIALOG_BODY}>
        {currentTier !== companyProduct.name && (
          <div>
            <Header>Tier Change</Header>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: '1 1 50%',
                marginTop: 10,
              }}>
              <Tag round className={'grey'}>
                {companyProduct.name}
              </Tag>
              {/* <Tag
                round
                style={{ height: 16 }}
                intent={
                  TiersToIntents[productCopy.tiers.indexOf(companyProduct.name)]
                }>
                {companyProduct.name}
              </Tag> */}
              <Icon
                name="arrow-right"
                style={{
                  marginRight: 20,
                  marginLeft: 20,
                  color: 'var(--light-text-color)',
                }}
              />
              <Tag
                round
                className={
                  currentTier === 'advanced'
                    ? 'advanced'
                    : currentTier === 'premium'
                    ? 'premium'
                    : 'basic'
                }
                style={{ transform: 'scale(1)' }}>
                {currentTier}
              </Tag>
            </div>
          </div>
        )}
        {currentTier !== companyProduct.name &&
          Boolean(Object.keys(manuallyManaged).length) && (
            <div style={{ height: 54 }} />
          )}
        {Boolean(Object.keys(manuallyManaged).length) && (
          <div>
            <Header>Feature Changes</Header>

            <table
              style={{
                color: 'var(--text-color)',
                width: '100%',
                borderCollapse: 'collapse',
                fontSize: 16,
              }}>
              <tbody>
                {Object.values(manuallyManaged).map((m) => (
                  <tr key={`p-${m.name}`}>
                    <td
                      style={{
                        borderBottom: '1px solid var(--border-color)',
                      }}>
                      {m.enabled ? (
                        <Tag round minimal intent={Intent.SUCCESS}>
                          ON
                        </Tag>
                      ) : (
                        <Tag round minimal intent={Intent.DANGER}>
                          OFF
                        </Tag>
                      )}
                    </td>
                    <td
                      style={{
                        padding: '10px 0',
                        borderBottom: '1px solid var(--border-color)',
                      }}>
                      {m.label}
                    </td>
                    {/* <td
                      style={{
                        textAlign: 'right',
                        borderBottom: '1px solid var(--border-color)',
                      }}>
                      {m.enabled ? (
                        <Tag round minimal intent={Intent.SUCCESS}>
                          ON
                        </Tag>
                      ) : (
                        <Tag round minimal intent={Intent.DANGER}>
                          OFF
                        </Tag>
                      )}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div
        style={{ marginRight: 10 }}
        className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button
          minimal
          style={{ color: 'var(--light-text-color)', width: 80 }}
          onClick={() => setContinuing(false)}>
          Go Back
        </Button>
        <Button
          style={{ width: 80 }}
          loading={mutating}
          onClick={() => {
            setMutating(true)
            client
              .mutate({
                mutation: productMutation,
                variables: {
                  input: {
                    companyProductId: companyProduct.id,
                    productTier: currentTier,
                    overrides: Object.values(manuallyManaged).map((mm) => {
                      return {
                        type: mm.type.substring(0, mm.type.length - 1),
                        enabled: mm.enabled,
                        name: mm.name,
                      }
                    }),
                  },
                },
              })
              .then(({ data }: { data?: IMutationPayload }) => {
                !!toasterRef.current &&
                  toasterRef.current.show({
                    message: 'Changes Successfully Saved',
                    intent: 'success',
                  })
                if (data) {
                  setCompanyProduct(data.updateCompanyProduct.companyProduct)
                  setCurrentTier(data.updateCompanyProduct.companyProduct.name)
                }
                setManuallyManaged({})
                setContinuing(false)
                setMutating(false)
              })
          }}
          intent="primary">
          Save
        </Button>
      </div>
    </Dialog>
  )
}

let Header = styled.div`
  font-size: 28px;
  padding-bottom: 14px;
  color: var(--text-color);
`
