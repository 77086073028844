import React from 'react'
import styled from 'styled-components'
import image from '../assets/home_logo@3x.png'

let ImageContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: linear-gradient(135deg, orange, #f44336);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`

let TitleContainer = styled.div`
  font-size: 20px;
  color: var(--text-color);
`

let Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 0px 25px;
`

let Small = styled.div`
  color: var(--light-text-color);
  font-size: 14px;
`

enum Size {
  SM,
  LG,
}

export default function Logo({ size }: { size?: Size }) {
  let cw = 40
  let ch = 40
  let iw = 26
  let ih = 26
  let font1 = 14
  let font2 = 20
  let marginLeft = 14

  if (size === Size.LG) {
    cw = 60
    ch = 60
    iw = 36
    ih = 36
    font1 = 16
    font2 = 28
    marginLeft = 24
  }

  return (
    <Wrapper>
      <ImageContainer style={{ width: cw, height: ch }}>
        <img alt="logo" src={image} width={iw} height={ih} />
      </ImageContainer>
      <div style={{ marginLeft }}>
        <Small style={{ fontSize: font1 }}>SpaceIQ</Small>
        <TitleContainer style={{ fontSize: font2 }}>
          Admin Portal
        </TitleContainer>
      </div>
    </Wrapper>
  )
}

Logo.Size = Size
