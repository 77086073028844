import { useState, useEffect } from 'react'
import gql from 'graphql-tag'

const query = gql`
  query user {
    admin {
      employee {
        name
      }
    }
  }
`

export default function User({ client }: { client: any }) {
  const [user, setUser] = useState<string>('')

  useEffect(() => {
    client
      .query({ query })
      .then(({ data: { admin: { employee: { name } } } }: IPayload) =>
        setUser(name)
      )
  }, [client])

  return user
}

interface IPayload {
  data: {
    admin: {
      employee: {
        name: string
      }
    }
  }
}
