import React, { useState, SyntheticEvent } from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { get } from 'lodash'
import { Query, QueryResult } from 'react-apollo'
import { Select, ItemRenderer, ItemPredicate } from '@blueprintjs/select'
import {
  Button,
  MenuItem,
  Position,
  Dialog,
  InputGroup,
  Classes,
  Intent,
  FormGroup,
} from '@blueprintjs/core'
import Icon from './UI/FAIcon'

const companiesQuery = gql`
  query companyList {
    admin {
      companies {
        name
        id
        uuid
        isLocked
      }
    }
  }
`

const createCompanyMutation = gql`
  mutation createCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      company {
        id
        uuid
        name
      }
    }
  }
`

export interface ICompany {
  name: string
  id: string
  uuid: string
  isLocked?: boolean
}

interface IPayload {
  admin: {
    companies: ICompany[]
  }
}

interface IMutationPayload {
  createCompany: {
    company: ICompany
  }
}

interface IFormErrors {
  name?: string
  domain?: string
}

interface IProps {
  setSelectedCompany: (c: ICompany) => void
  selectedCompany: ICompany | undefined
  create: boolean
}

const filterCompanies: ItemPredicate<ICompany> = (
  query,
  company,
  index,
  exactMatch
) => {
  const normalizedName = company.name.toLowerCase()
  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedName === normalizedQuery
  }
  return normalizedName.indexOf(normalizedQuery) >= 0
}

export default function CompanySelector({
  setSelectedCompany,
  selectedCompany,
  create,
}: IProps) {
  let CompanySelect = Select.ofType<ICompany>()
  let [errors, setErrors] = useState<IFormErrors>({})
  let [showNewModal, setShowNewModal] = useState<boolean>(false)
  let [newName, setNewName] = useState<string>('')
  let [newDomain, setNewDomain] = useState<string>('')
  let [mutating, setMutating] = useState<boolean>(false)

  let listItems: ICompany[]
  let companyRenderer: ItemRenderer<ICompany> = (
    c,
    { handleClick, modifiers, query }
  ) => (
    <MenuItem
      key={c.id}
      onClick={handleClick}
      text={c.name}
      active={modifiers.active}
      icon={
        c.id === 'create' ? <Icon name="plus" style={{ marginTop: 3 }} /> : null
      }
      intent={c.id === 'create' ? 'primary' : 'none'}
    />
  )

  return (
    <Query query={companiesQuery}>
      {({ data, loading, client, refetch }: QueryResult<IPayload>) => {
        if (!data || loading) {
          return (
            <Button
              fill
              disabled
              intent={Intent.PRIMARY}
              text="Select a Company"
              rightIcon="chevron-down"
              style={{ width: 300 }}
            />
          )
        }

        let {
          admin: { companies },
        } = data

        let createItem = {
          id: 'create',
          name: 'Create New Company',
          uuid: '1000',
        }

        let onCompanySelect = (c: ICompany) => {
          if (c.id !== 'create') {
            setSelectedCompany(c)
          } else {
            setShowNewModal(true)
          }
        }

        create
          ? (listItems = [createItem, ...companies])
          : (listItems = companies)

        let createCompany = (e: SyntheticEvent<HTMLFormElement>) => {
          e.preventDefault()
          setMutating(true)
          client
            .mutate({
              mutation: createCompanyMutation,
              variables: { input: { name: newName, domain: newDomain } },
            })
            .then((result) => {
              const data = result.data as IMutationPayload | undefined
              if (!data) {
                console.error('No data returned from mutation')
                return
              }
              const { company: createdCompany } = data.createCompany
              setSelectedCompany(createdCompany)
              setShowNewModal(false)
              setMutating(false)
              refetch().then(
                ({ data }: { data: IPayload }) =>
                  (listItems = [createItem, ...data.admin.companies])
              )
            })
            .catch((error) => {
              setMutating(false)
              setErrors(get(error, 'graphQLErrors[0].invalidArguments', {}))
            })
        }

        let onClose = () => {
          if (!mutating) {
            setShowNewModal(false)
            setErrors({})
            setNewName('')
            setNewDomain('')
          }
        }

        return (
          <>
            <CompanySelect
              itemPredicate={filterCompanies}
              items={listItems}
              itemRenderer={companyRenderer}
              noResults={<MenuItem disabled={true} text="No results." />}
              onItemSelect={onCompanySelect}
              popoverProps={{
                position: Position.BOTTOM,
                minimal: true,
                hasBackdrop: true,
                backdropProps: {
                  style: { backgroundColor: 'rgba(0,0,0,0.3)' },
                },
                popoverClassName: 'company-selector',
                usePortal: true,
              }}>
              <Button
                fill
                intent={Intent.PRIMARY}
                rightIcon="chevron-down"
                text={
                  selectedCompany ? selectedCompany.name : 'Select a Company'
                }
                style={{
                  width: 300,
                }}
              />
            </CompanySelect>
            <Dialog
              title=""
              style={{ position: 'relative', width: 380 }}
              isCloseButtonShown={false}
              isOpen={showNewModal}
              onClose={onClose}>
              <form onSubmit={createCompany}>
                <div className={Classes.DIALOG_BODY}>
                  <Button
                    large
                    minimal
                    className="round"
                    style={{ position: 'absolute', top: 5, right: 5 }}
                    onClick={onClose}>
                    <Icon name="times" style={{ color: 'var(--text-color)' }} />
                  </Button>
                  <Header>New Company</Header>
                  <FormGroup
                    intent={errors.name ? Intent.DANGER : undefined}
                    label="Name"
                    labelInfo="*"
                    helperText={
                      errors.name ? `Name ${errors.name}` : undefined
                    }>
                    <InputGroup
                      large
                      placeholder="SpaceIQ"
                      className="bordered"
                      value={newName}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setErrors({ ...errors, name: undefined })
                        setNewName(event.target.value)
                      }}
                    />
                  </FormGroup>
                  <FormGroup
                    intent={errors.domain ? Intent.DANGER : undefined}
                    label="Domain"
                    labelInfo="*"
                    helperText={
                      errors.domain ? `Domain ${errors.domain}` : undefined
                    }>
                    <InputGroup
                      large
                      placeholder="spaceiq.com"
                      className="bordered"
                      value={newDomain}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setErrors({ ...errors, domain: undefined })
                        setNewDomain(event.target.value)
                      }}
                    />
                  </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button
                    fill
                    large
                    type="submit"
                    intent="primary"
                    disabled={!newDomain || !newName}
                    loading={mutating}>
                    Save
                  </Button>
                </div>
              </form>
            </Dialog>
          </>
        )
      }}
    </Query>
  )
}

let Header = styled.div`
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
  color: var(--text-color);
`
