import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { Select, ItemRenderer } from '@blueprintjs/select'
import { Button, MenuItem, Intent } from '@blueprintjs/core'
import Icon from './UI/FAIcon'
import moment from 'moment'
import { ApolloClient } from 'apollo-boost'
import { ICompany } from './CompanySelector'

const mutation = gql`
  mutation apSync($input: SyncAssignmentPlanInput!) {
    syncAssignmentPlan(input: $input) {
      assignmentPlan {
        id
      }
    }
  }
`

export interface IAssignmentPlan {
  id: string
  name: string
  alias: string | null
  apType: 'master' | 'scenario'
  deployAt: Date
}

const APSelect = Select.ofType<IAssignmentPlan>()

export default function APSelector({
  assignmentPlans,
  client,
  selectedCompany,
}: {
  assignmentPlans: IAssignmentPlan[]
  client: ApolloClient<any>
  selectedCompany?: ICompany
}) {
  const [selectedAP, setSelectedAP] = useState<null | IAssignmentPlan>(null)
  const [disableButton, setDisableButton] = useState(false)

  let resyncAP = () => {
    if (selectedAP) {
      setDisableButton(true)

      client
        .mutate<any, { input: { id: string } }>({
          mutation,
          variables: { input: { id: selectedAP.id } },
        })
        .catch((e) => console.log(e))
    }
  }

  useEffect(() => {
    if (disableButton) {
      let timer = setTimeout(() => setDisableButton(false), 60 * 1000)
      return () => clearTimeout(timer)
    }
  }, [disableButton])

  const createAPName = (ap: IAssignmentPlan) =>
    ap.name === 'Current'
      ? ap.name
      : ap.apType === 'master'
      ? moment.utc(ap.deployAt).format('MMM D, YYYY')
      : `${moment.utc(ap.deployAt).format('MMM D, YYYY')}${
          ap.name ? ` - ${ap.name}` : ''
        }`

  const apRenderer: ItemRenderer<IAssignmentPlan> = (
    ap,
    { handleClick, modifiers }
  ) => (
    <MenuItem
      key={ap.id}
      onClick={handleClick}
      text={createAPName(ap)}
      active={modifiers.active}
      intent="none"
    />
  )

  return (
    <Container>
      <APSelect
        disabled={selectedCompany && selectedCompany.isLocked}
        items={assignmentPlans}
        itemRenderer={apRenderer}
        onItemSelect={(i) => setSelectedAP(i)}
        popoverProps={{ minimal: true }}>
        <Button
          fill
          intent={Intent.WARNING}
          rightIcon="chevron-down"
          text={
            selectedCompany && selectedCompany.isLocked
              ? 'Select an AP'
              : selectedAP
              ? createAPName(selectedAP)
              : 'Select an AP'
          }
          style={{
            width: 250,
            color: '#fff',
          }}
        />
      </APSelect>
      {selectedCompany && !selectedCompany.isLocked && selectedAP && (
        <>
          <Button
            large
            minimal
            className="round"
            style={{
              marginLeft: 10,
              width: 20,
            }}
            loading={disableButton}
            rightIcon={
              <Icon name="sync" style={{ color: 'var(--light-text-color)' }} />
            }
            onClick={() => resyncAP()}
          />

          <Button
            large
            minimal
            className="round"
            style={{
              marginLeft: 10,
              width: 20,
            }}
            rightIcon={
              <Icon name="times" style={{ color: 'var(--light-text-color)' }} />
            }
            onClick={() => setSelectedAP(null)}
          />
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 50px;
  display: flex;
  justify-content: center;
  left: 00px;
  right: 0;
  z-index: 1;
  height: 50px;
  color: var(--text-color);
  background: var(--appbar-background-color);
`
