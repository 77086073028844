import React from 'react'
import { Tag, Card, Intent } from '@blueprintjs/core'
import styled from 'styled-components'
import { VoidF, IProduct } from './index'
import { IDefinition } from './useProductQuery'
import { ICompany } from '../CompanySelector'
import './company_lock.css'

export interface IManual {
  type: string
  name: string
  enabled: boolean
  label: string
}

export default function CompanyPanel({
  currentTier,
  onTierClick,
  toggleFeature,
  features,
  reports,
  integrations,
  tiers,
  companyProduct,
  selectedCompany = { isLocked: false, name: '', id: '', uuid: '' },
}: {
  currentTier: string
  onTierClick: (nextTier: string, prevTier: string) => void
  toggleFeature: VoidF<IManual>
  features: IDefinition
  reports: IDefinition
  integrations: IDefinition
  tiers: string[]
  companyProduct: IProduct
  selectedCompany?: ICompany
}) {
  return (
    <div style={{ paddingTop: 100 }}>
      <Sticky>
        <StickyInner>
          <Header>
            <Tag
              round
              className={currentTier === 'basic' ? 'basic' : 'grey'}
              onClick={() => {
                if (!selectedCompany.isLocked) {
                  onTierClick('basic', currentTier)
                }
              }}>
              <div className={selectedCompany.isLocked ? 'companyLock' : ''}>
                basic
              </div>
            </Tag>
          </Header>
          <Header>
            <Tag
              round
              className={currentTier === 'advanced' ? 'advanced' : 'grey'}
              onClick={() => {
                if (!selectedCompany.isLocked) {
                  onTierClick('advanced', currentTier)
                }
              }}>
              <div className={selectedCompany.isLocked ? 'companyLock' : ''}>
                advanced
              </div>
            </Tag>
          </Header>
          <Header>
            <Tag
              round
              className={currentTier === 'premium' ? 'premium' : 'grey'}
              onClick={() => {
                if (!selectedCompany.isLocked) {
                  onTierClick('premium', currentTier)
                }
              }}>
              <div className={selectedCompany.isLocked ? 'companyLock' : ''}>
                premium
              </div>
            </Tag>
          </Header>
        </StickyInner>
      </Sticky>
      <Section
        name="Features"
        data={features}
        toggleFeature={toggleFeature}
        tiers={tiers}
        companyProduct={companyProduct}
        selectedCompany={selectedCompany}
      />
      <Section
        name="Reports"
        data={reports}
        toggleFeature={toggleFeature}
        tiers={tiers}
        companyProduct={companyProduct}
        selectedCompany={selectedCompany}
      />
      <Section
        name="Integrations"
        data={integrations}
        toggleFeature={toggleFeature}
        tiers={tiers}
        companyProduct={companyProduct}
        selectedCompany={selectedCompany}
      />
    </div>
  )
}

function Section({
  name,
  data,
  toggleFeature,
  tiers,
  selectedCompany,
}: {
  name: string
  data: IDefinition
  toggleFeature: VoidF<IManual>
  tiers: string[]
  companyProduct: IProduct
  selectedCompany?: ICompany
}) {
  return (
    <div>
      <SectionName>{name}</SectionName>
      <div style={{ display: 'flex' }}>
        {tiers.map((t, i, a) => (
          <GroupView
            dataName={name.toLowerCase()}
            key={`${t}-card`}
            data={data}
            tier={t}
            tiers={tiers}
            toggleFeature={toggleFeature}
            size={a.length}
            selectedCompany={selectedCompany}
          />
        ))}
      </div>
    </div>
  )
}

function GroupView({
  dataName,
  data,
  tier,
  tiers,
  toggleFeature,
  size,
  selectedCompany,
}: {
  dataName: string
  data: IDefinition
  tier: string
  tiers: string[]
  toggleFeature: VoidF<IManual>
  size: number
  selectedCompany?: ICompany
}) {
  const isDisabledClass =
    selectedCompany && selectedCompany.isLocked ? 'companyLock' : ''
  return (
    <div
      id="checker-thing"
      style={{ margin: 5, width: `calc(${100 / size}% - 5px)` }}>
      {Object.entries(data)
        .filter((d) => d[1].tier === tier)
        .map((d) => {
          return (
            <Card
              key={`group-card-${d[0]}`}
              className={
                d[1].active
                  ? `on ${isDisabledClass}`
                  : `off  ${isDisabledClass}`
              }
              style={{ marginBottom: 10 }}
              onClick={() => {
                if (selectedCompany && !selectedCompany.isLocked) {
                  toggleFeature({
                    type: dataName,
                    name: d[0],
                    enabled: !d[1].active,
                    label: d[1].label,
                  })
                }
              }}>
              <div key={`card-${d[0]}`}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <div style={{ fontSize: 16, color: 'var(--text-color)' }}>
                    {d[1].label}
                  </div>{' '}
                  <Tag
                    round
                    minimal
                    intent={d[1].active ? Intent.SUCCESS : Intent.DANGER}>
                    <div
                      className={
                        selectedCompany && selectedCompany.isLocked
                          ? 'companyLock'
                          : ''
                      }>
                      {d[1].active ? 'ON' : 'OFF'}
                    </div>
                  </Tag>
                </div>
                <div style={{ color: 'var(--light-text-color)' }}>
                  {' '}
                  {d[1].description ? d[1].description : null}
                </div>
              </div>
            </Card>
          )
        })}
    </div>
  )
}

let Sticky = styled.div`
  position: fixed;
  top: 100px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background: var(--appbar-background-color);
  border-bottom: 1px solid var(--border-color);
`

let StickyInner = styled.div`
  display: flex;
  text-align: center;
  padding: 14px 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

let Header = styled.div`
  width: calc(100% - 5px);
  padding: 5px;
  z-index; 100;
`

let SectionName = styled.div`
  padding-left: 10px;
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 14px;
  color: var(--text-color);
`
