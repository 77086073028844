export const aphroditeUrl: string =
  process.env.REACT_APP_APHRODITE ||
  (() => {
    if (window.location.hostname.indexOf('.') >= 0) {
      const hostParts = window.location.hostname.split('.')
      // QA enviroments have 2 subdomains
      if (hostParts.length === 3) {
        const firstPart = hostParts[0].split('-')
        firstPart[firstPart.length - 1] = 'main'

        hostParts[0] = firstPart.join('-')
      } else throw new Error('Unable to derive aphrodite URL')
      return `https://${hostParts.join('.')}`
    } else if (process.env.NODE_ENV !== 'production') {
      return 'http://localhost:3000'
    } else {
      throw new Error(
        'Unable to derive aphrodite URL, REACT_APP_APHRODITE undefined'
      )
    }
  })()

export const athenaUrl: string =
  process.env.REACT_APP_ATHENA ||
  (() => {
    if (window.location.hostname.indexOf('.') >= 0) {
      const hostParts = window.location.hostname.split('.')
      // QA enviroments have 2 subdomains
      if (hostParts.length === 3) {
        const firstPart = hostParts[0].split('-')
        firstPart[firstPart.length - 1] = 'api'

        hostParts[0] = firstPart.join('-')
      } else throw new Error('Unable to derive athena URL')
      return `https://${hostParts.join('.')}`
    } else if (process.env.NODE_ENV !== 'production') {
      return 'http://localhost:3001'
    } else {
      throw new Error('Unable to derive athena URL, REACT_APP_ATHENA undefined')
    }
  })()
