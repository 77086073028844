import gql from 'graphql-tag'
import { useEffect, useState } from 'react'

let query = gql`
  query productCatalog {
    admin {
      productCatalog {
        name
        features {
          name
          label
          description
        }
        integrations {
          name
          label
          description
        }
        reports {
          name
          label
          description
        }
      }
    }
  }
`

// this enum is for sorting
export enum Tier {
  basic,
  advanced,
  premium,
}

export type ISimpleTier = 'basic' | 'premium' | 'advanced'

export interface IDefinition {
  [name: string]: {
    label: string
    description?: string
    tier: string
    active: boolean
  }
}

export interface IFeat {
  name: string
  label: string
  description?: string
}

interface ITier {
  name: string
  features: IFeat[]
  integrations: IFeat[]
  reports: IFeat[]
}

interface IPayload {
  data: {
    admin: {
      productCatalog: ITier[]
    }
  }
}

interface IOutput {
  tiers: string[]
  features: IDefinition
  integrations: IDefinition
  reports: IDefinition
}

const placeHolder: IOutput = {
  tiers: ['test'],
  features: { test: { label: 'test', tier: 'basic', active: false } },
  integrations: { test: { label: 'test', tier: 'basic', active: false } },
  reports: { test: { label: 'test', tier: 'basic', active: false } },
}

export default function useProductCatalog({ client }: { client: any }) {
  let [products, setProducts] = useState<IOutput>(placeHolder)

  useEffect(() => {
    client
      .query({ query })
      .then(({ data: { admin: { productCatalog } } }: IPayload) => {
        let output: IOutput = {
          tiers: [],
          features: {},
          reports: {},
          integrations: {},
        }
        productCatalog.forEach(tier => {
          let tierName: string
          Object.entries(tier).forEach(([key, value]) => {
            if (key === 'name') {
              tierName = value
            } else if (key === '__typename') {
              return
            } else if (
              key === 'features' ||
              key === 'reports' ||
              key === 'integrations'
            ) {
              // true features / integrations / reports
              let category: 'features' | 'reports' | 'integrations' = key
              value.forEach((f: IFeat) => {
                let temp = {
                  [`${f.name}`]: { tier: tierName, active: false, ...f },
                }
                if (!Boolean(output[category][f.name])) {
                  output[category] = { ...output[category], ...temp }
                  if (!output.tiers.includes(temp[`${f.name}`].tier)) {
                    output.tiers.push(tierName)
                  }
                }
              })
            }
          })
        })
        setProducts(output)
      })
  }, [client])

  return products
}
