import React, { useState, useRef, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { omit, cloneDeep, get } from 'lodash'
import { withApollo, WithApolloClient, Query, QueryResult } from 'react-apollo'
import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-boost'
import styled from 'styled-components'
import {
  Button,
  Tooltip,
  Spinner,
  Intent,
  OverlayToaster,
} from '@blueprintjs/core'
import { removeCookie } from '../../helpers/cookies'
import Appbar from '../AppBar'
import CompanyPanel, { IManual } from './CompanyPanel'
import CompanySelector, { ICompany } from '../CompanySelector'
import ChangesDialog from './ChangesDialog'
import Icon from '../UI/FAIcon'
import { animated, useTransition, config } from 'react-spring'
import useProductQuery from './useProductQuery'
import { aphroditeUrl, athenaUrl } from '../../backend'
import APSelector, { IAssignmentPlan } from '../APSelector'
import CompanyToolSelector, { ITools } from '../CompanyToolSelector'

interface IProps {
  client?: ApolloClient<any>
  setAccessToken: VoidF<string>
  path?: string
}

export const TiersToIntents: Intent[] = [
  Intent.NONE,
  Intent.PRIMARY,
  Intent.WARNING,
  Intent.DANGER,
]

export interface IProductCompany {
  id: string
  name: string
  product: {
    name: string
    features: string[]
    reports: string[]
  }
}

export type VoidF<T> = (s: T) => void

let companyQuery = gql`
  query company($id: ID!) {
    node(id: $id) {
      ... on Company {
        name
        id
        product {
          id
          name
          features
          reports
          integrations
        }
        assignmentPlans(types: [master, scenario]) {
          id
          name
          alias
          apType
          deployAt
          createdAt
        }
      }
    }
  }
`
const updateCompanySsoMutation = gql`
  mutation updateCompanySso($input: UpdateCompanySsoInput!) {
    updateCompanySso(input: $input) {
      message
    }
  }
`
const lockUnlockCompanyMutation = gql`
  mutation lockUnlockCompanyMutation($input: LockUnlockCompanyInput!) {
    lockUnlockCompany(input: $input) {
      company {
        name
        id
        uuid
        isLocked
      }
    }
  }
`

interface IPayload {
  node: {
    name: string
    id: string
    product: IProduct
    assignmentPlans: IAssignmentPlan[]
  }
}

export interface IProduct {
  name: string
  features: string[]
  reports: string[]
  integrations: string[]
  id: string
}

function ProductManager({
  path,
  client,
  setAccessToken,
}: WithApolloClient<IProps & RouteComponentProps>) {
  let [redirecting, setRedirecting] = useState(false)
  let [selectedCompany, setSelectedCompany] = useState<undefined | ICompany>()
  let [currentTier, setCurrentTier] = useState<string>('')
  let productDef = useProductQuery({ client })
  let [showConfirm, setShowConfirm] = useState<boolean>(false)
  let [isLockUnlockClicked, setIsLockUnlockClicked] = useState<boolean>(false)
  let [confirmBtnDisabled, setConfirmBtnDisabled] = useState<boolean>(false)

  let [manuallyManaged, setManuallyManaged] = useState<{
    [name: string]: IManual
  }>({})
  let toasterRef = useRef<OverlayToaster>(null)
  let [continuing, setContinuing] = useState<boolean>(false)
  const transitions = useTransition(showConfirm, null, {
    config: config.stiff,
    from: { bottom: -50 },
    enter: { bottom: 0 },
    leave: { bottom: -50 },
  })
  let formRef = useRef<HTMLButtonElement>(null)
  let [companyToken, setCompanyToken] = useState('')

  let toggleFeature: VoidF<IManual> = (feature) => {
    if (manuallyManaged[feature.name]) {
      setManuallyManaged(omit(manuallyManaged, [feature.name]))
    } else {
      setManuallyManaged({ ...manuallyManaged, [`${feature.name}`]: feature })
    }
  }

  let isCompanyLocked = selectedCompany && selectedCompany.isLocked

  let lockUnlockFunction = {
    onClick: () => {
      setConfirmBtnDisabled(true)
      client
        .mutate({
          mutation: lockUnlockCompanyMutation,
          variables: {
            input: {
              type: isCompanyLocked ? 'unlock' : 'lock',
              companyId: selectedCompany ? selectedCompany.id : '',
            },
          },
        })
        .then(({ data }) => {
          setSelectedCompany(data.lockUnlockCompany.company)
          setIsLockUnlockClicked(false)
          setConfirmBtnDisabled(false)
          !!toasterRef.current &&
            toasterRef.current.show({
              message: `${selectedCompany && selectedCompany.name} has been ${
                isCompanyLocked ? 'unlocked' : 'locked'
              }`,
              intent: 'success',
            })
        })
        .catch((error) => {
          setIsLockUnlockClicked(false)
          setConfirmBtnDisabled(false)
          !!toasterRef.current &&
            toasterRef.current.show({
              message: get(error, 'graphQLErrors[0].message', {}),
              intent: 'danger',
            })
        })
    },
  }
  let resetSsoOption = {
    text: 'Reset SSO',
    onClick: () => {
      client
        .mutate({
          mutation: updateCompanySsoMutation,
          variables: {
            input: {
              companyId: selectedCompany ? selectedCompany.id : '',
            },
          },
        })
        .then(({ data }) => {
          !!toasterRef.current &&
            toasterRef.current.show({
              message: data.updateCompanySso.message,
              intent: 'success',
            })
        })
        .catch((error) => {
          !!toasterRef.current &&
            toasterRef.current.show({
              message: get(error, 'graphQLErrors[0].message', {}),
              intent: 'danger',
            })
        })
    },
  }

  let loginOption = {
    text: `Login to ${selectedCompany ? selectedCompany.name : ''}`,

    onClick: () => {
      setRedirecting(true)
      fetch(`${athenaUrl}/auth`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_token: window.localStorage.getItem('_jX22Dksj&.K'),
          company_id: selectedCompany ? selectedCompany.uuid : '',
        }),
      })
        .then((res) => res.json())
        .then(async (data) => {
          setRedirecting(false)
          if ('error' in data) {
            removeCookie('_jX22Dksj&.K')
            setAccessToken('')
          } else {
            setCompanyToken(data.access_token)
          }
        })
        .catch(() => setRedirecting(false))
    },
  }

  let lockUnlockOption = {
    text: `${isCompanyLocked ? 'Unlock' : 'Lock'} ${
      selectedCompany ? selectedCompany.name : ''
    }`,
    onClick: () => {
      setIsLockUnlockClicked(true)
    },
  }

  const adminToolEmail =
    window.localStorage.getItem('_admekjswoemal') === 'dhwgfkjr'

  const buttons: ITools[] = [loginOption].concat(
    adminToolEmail ? [resetSsoOption, lockUnlockOption] : []
  )

  useEffect(() => {
    if (companyToken && formRef && formRef.current) {
      formRef.current.click()
      setCompanyToken('')
    }
  }, [companyToken])

  useEffect(() => {
    setShowConfirm(false)
    setManuallyManaged({})
    setCurrentTier('')
  }, [selectedCompany])

  if (!productDef) {
    return <div />
  } else {
    return (
      <form action={aphroditeUrl + '/company_login'} method="POST">
        <input
          type="text"
          id="company_token"
          name="company_token"
          value={companyToken}
          style={{ display: 'none' }}
        />
        <button type="submit" style={{ display: 'none' }} ref={formRef} />
        <Appbar title="Product Manager" setAccessToken={setAccessToken}>
          <OverlayToaster ref={toasterRef} position="bottom" />
          <CompanySelector
            setSelectedCompany={setSelectedCompany}
            selectedCompany={selectedCompany}
            create={true}
          />
          {Boolean(selectedCompany) ? (
            <CompanyToolSelector buttons={buttons} />
          ) : (
            <div />
          )}
        </Appbar>
        <Main>
          {selectedCompany && (
            <Query
              query={companyQuery}
              variables={{ id: selectedCompany ? selectedCompany.id : '' }}>
              {({ data, loading, client }: QueryResult<IPayload>) => {
                if (loading || !data || !data.node) {
                  return (
                    <NoCompany>
                      <Spinner />
                    </NoCompany>
                  )
                }
                let exceptions: string[] = [] // used for keeping track of overrides during a tier downgrade
                let companyProduct = data.node.product
                let companyProdCopy = cloneDeep(companyProduct)
                let productCopy = cloneDeep(productDef)
                let setCompanyProduct = (product: IProduct) =>
                  (companyProduct = product)
                const assignmentPlans = data.node.assignmentPlans

                if (!currentTier) {
                  setCurrentTier(companyProduct.name)
                }

                //activating features based on company product
                Object.entries(companyProduct).forEach(
                  ([key, value]: [string, string | string[]]) => {
                    if (
                      (key === 'features' ||
                        key === 'integrations' ||
                        key === 'reports') &&
                      Array.isArray(value)
                    ) {
                      value.forEach((v: string) => {
                        if (productCopy[key][v]) {
                          productCopy[key][v].active = true
                          if (
                            productCopy.tiers.indexOf(
                              productCopy[key][v].tier
                            ) > productCopy.tiers.indexOf(companyProduct.name)
                          ) {
                            exceptions.push(v)
                          }
                        }
                      })
                    }
                  }
                )

                //activating features based on tier
                Object.entries(productCopy).forEach(([key, value], i, pc) => {
                  if (
                    currentTier !== companyProduct.name &&
                    (key === 'features' ||
                      key === 'integrations' ||
                      key === 'reports')
                  ) {
                    Object.entries<{
                      label: string
                      tier: string
                      active: boolean
                    }>(value).forEach(([name, obj]) => {
                      if (!exceptions.includes(name)) {
                        obj.active =
                          productDef.tiers.indexOf(obj.tier) <=
                          productDef.tiers.indexOf(currentTier)
                      } else {
                        obj.active = true
                      }
                    })
                  }
                })

                // activating features based on added/removed
                Object.entries(manuallyManaged).forEach(([key, value]) => {
                  if (
                    value.type === 'features' ||
                    value.type === 'integrations' ||
                    value.type === 'reports'
                  )
                    productCopy[value.type][value.name].active = value.enabled
                })

                let resetChanges = () => {
                  setManuallyManaged({})
                  setCurrentTier(companyProduct.name)
                }

                let onTierClick = (newTabId: string, prevTabId: string) => {
                  if (
                    newTabId !== prevTabId &&
                    newTabId !== companyProduct.name
                  ) {
                    setCurrentTier(newTabId)
                  } else if (newTabId === companyProduct.name) {
                    setCurrentTier(companyProduct.name)
                  }
                }

                if (
                  Boolean(currentTier !== companyProduct.name) ||
                  Boolean(Object.keys(manuallyManaged).length)
                ) {
                  setShowConfirm(true)
                } else {
                  setShowConfirm(false)
                }

                return (
                  <>
                    <Company>
                      <APSelector
                        client={client}
                        assignmentPlans={assignmentPlans}
                        selectedCompany={selectedCompany}
                      />
                      <CompanyPanel
                        companyProduct={companyProdCopy}
                        toggleFeature={toggleFeature}
                        features={productCopy.features}
                        reports={productCopy.reports}
                        integrations={productCopy.integrations}
                        tiers={productCopy.tiers}
                        currentTier={currentTier}
                        onTierClick={onTierClick}
                        selectedCompany={selectedCompany}
                      />
                    </Company>
                    {transitions.map(
                      ({ item, key, props }) =>
                        item && (
                          <animated.div
                            key={key}
                            style={{
                              position: 'fixed',
                              bottom: 0,
                              left: 0,
                              right: 0,
                              height: 100,
                              zIndex: 10,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              background: 'var(--tooltip-background-color)',
                              ...props,
                            }}>
                            <ConfirmationPanel>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}>
                                <ConfirmationMessage>
                                  <Icon
                                    name="bomb"
                                    style={{
                                      marginRight: 14,
                                      fontSize: 28,
                                      color: 'var(--danger-color)',
                                    }}
                                  />
                                  Changes detected, would you like to save?
                                </ConfirmationMessage>
                                <div>
                                  <Button
                                    minimal
                                    onClick={resetChanges}
                                    style={{
                                      marginRight: 8,
                                      color: 'var(--tooltip-text-color)',
                                    }}>
                                    Cancel
                                  </Button>
                                  <Button
                                    intent="primary"
                                    onClick={() => setContinuing(true)}>
                                    Continue
                                  </Button>
                                </div>
                              </div>
                            </ConfirmationPanel>
                          </animated.div>
                        )
                    )}

                    {isLockUnlockClicked && (
                      <animated.div
                        style={{
                          position: 'fixed',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          height: 100,
                          zIndex: 10,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          background: 'var(--tooltip-background-color)',
                        }}>
                        <ConfirmationPanel>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>
                            <ConfirmationMessage>
                              <Icon
                                name="bomb"
                                style={{
                                  marginRight: 14,
                                  fontSize: 28,
                                  color: 'var(--danger-color)',
                                }}
                              />
                              Are you sure you want to
                              {selectedCompany && selectedCompany.isLocked
                                ? ' unlock '
                                : ' lock '}
                              {selectedCompany && selectedCompany.name}?
                            </ConfirmationMessage>
                            <div>
                              <Button
                                minimal
                                onClick={() => {
                                  setIsLockUnlockClicked(false)
                                }}
                                style={{
                                  marginRight: 8,
                                  color: 'var(--tooltip-text-color)',
                                }}>
                                Cancel
                              </Button>
                              <Button
                                disabled={confirmBtnDisabled}
                                intent="primary"
                                onClick={lockUnlockFunction.onClick}>
                                Continue
                              </Button>
                            </div>
                          </div>
                        </ConfirmationPanel>
                      </animated.div>
                    )}

                    <ChangesDialog
                      toasterRef={toasterRef}
                      isOpen={continuing}
                      selectedCompany={selectedCompany}
                      companyProduct={companyProduct}
                      productCopy={productCopy}
                      manuallyManaged={manuallyManaged}
                      currentTier={currentTier}
                      setContinuing={setContinuing}
                      setCurrentTier={setCurrentTier}
                      setManuallyManaged={setManuallyManaged}
                      client={client}
                      setCompanyProduct={setCompanyProduct}
                    />
                  </>
                )
              }}
            </Query>
          )}
        </Main>
      </form>
    )
  }
}

export default withApollo<IProps>(ProductManager)

const Main = styled.div`
  margin-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
  position: relative;
`

export const ButtonRow = styled.div<{ many?: boolean }>`
  display: flex;
  justify-content: ${({ many }) => (many ? 'space-between' : 'flex-end')};
  margin: 10px 0;
`

const ConfirmationPanel = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  color: var(--tooltip-text-color);
`

const ConfirmationMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`

// for when no company selected
const NoCompany = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Company = styled.div`
  display: flex;
  flex-direction: column;
`
