import React from 'react'
import { useTransition, animated } from 'react-spring'
import {
  Callout,
  InputGroup,
  Button,
  Intent,
  OverlayToaster,
  Position,
} from '@blueprintjs/core'
import styled from 'styled-components'
import Icon from '../UI/FAIcon'
import { RouteComponentProps } from '@reach/router'
import Logo from '../Logo'
import { athenaUrl } from '../../backend'

interface Props {
  setAccessToken: (token: string) => void
  changeMode: () => void
}

let toaster: OverlayToaster | null = null

export default function LoginScreen({
  changeMode,
  setAccessToken,
}: Props & RouteComponentProps) {
  const [activeForm, setActiveFrom] = React.useState<'email' | 'passcode'>(
    'email'
  )
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [otp, setOtp] = React.useState('') // OTP - one time passcode
  const [fetching, setFetching] = React.useState(false)
  const [codeResending, setCodeResending] = React.useState(false)
  const [error, setError] = React.useState<undefined | Error>()
  const toast = (t: any) => {
    if (!toaster) return
    t.timeout = 5000
    toaster.show(t)
  }

  const transitions = useTransition(activeForm === 'email', null, {
    from: (item) => {
      return {
        position: 'absolute',
        opacity: item ? 1 : 0,
        width: 350,
        transform: `translateX(${item ? '0%' : '100%'})`,
      }
    },
    enter: { opacity: 1, transform: 'translateX(0p%)' },
    leave: { opacity: 0, transform: 'translateX(-50%)' },
  })

  const data = {
    email,
    password,
    otp_code: otp,
  }

  const configInit: RequestInit = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  }

  const onLogin = (event: React.FormEvent) => {
    event.preventDefault()
    setFetching(true)

    return fetch(`${athenaUrl}/auth`, configInit)
      .then((res) => res.json())
      .then((data) => {
        if ('error' in data) {
          if (typeof data.error === 'string') throw new Error(data.error)

          if ('meta' in data.error) {
            setFetching(false)
            let meta = data.error.meta
            if (meta.needs_otp) {
              setError(undefined)
              setActiveFrom('passcode')
              return
            }
          }
        }

        fetch(`${athenaUrl}/admin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${data.access_token}`,
          },
          body: JSON.stringify({ query: '{ admin { role } }' }),
        })
          .then((res) => res.json())
          .then((d) => {
            if ('error' in d) throw new Error(d.error)
            else {
              setAccessToken(data.access_token)
              window.localStorage.setItem('_jX22Dksj&.K', data.access_token)
            }
          })
          .catch((e) => {
            setFetching(false)
            setError(e)
          })
      })
      .catch((e) => {
        setFetching(false)
        setError(e)
      })
  }

  const resendPasscode = () => {
    setCodeResending(true)

    return fetch(`${athenaUrl}/auth`, {
      ...configInit,
      body: JSON.stringify({ email, password }),
    }).then(() => {
      setCodeResending(false)
      toast({ message: 'Passcode resent', intent: Intent.SUCCESS })
    })
  }

  let disabledSubmitButton =
    activeForm === 'email' ? !email || !password : !otp || otp.length !== 6

  return (
    <Main>
      <OverlayToaster
        position={Position.BOTTOM}
        ref={(ref) => {
          toaster = ref
        }}
      />
      <LoginWrapper>
        <div onClick={changeMode}>
          <LoginHeaderWrapper>
            <Logo size={Logo.Size.LG} />
          </LoginHeaderWrapper>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onLogin(e)
          }}>
          {error && (
            <div style={{ marginBottom: 14 }}>
              <Callout
                icon={<Icon name="exclamation-triangle" />}
                intent={Intent.DANGER}>
                {error.message}
              </Callout>
            </div>
          )}
          {transitions.map(({ item, key, props }) =>
            item ? (
              <animated.div style={props} key={key}>
                <InputWrapper>
                  <InputGroup
                    large
                    autoFocus
                    autoComplete="off"
                    name="email"
                    placeholder="Email..."
                    style={{
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottom: '1px solid var(--border-color)',
                    }}
                    leftIcon={<Icon name="user-ninja" />}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                    value={email}
                  />
                  <InputGroup
                    large
                    name="password"
                    placeholder="Password..."
                    style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                    leftIcon={<Icon name="lock" />}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    value={password}
                    type="password"
                  />
                </InputWrapper>
                <Button
                  large
                  fill
                  type="submit"
                  intent={Intent.PRIMARY}
                  text="Signin"
                  loading={fetching}
                  disabled={disabledSubmitButton}
                />
              </animated.div>
            ) : (
              <animated.div style={props} key={key}>
                <OTPMessage>
                  Check your email for a one-time passcode from SpaceIQ.
                </OTPMessage>
                <InputWrapper>
                  <InputGroup
                    large
                    autoFocus
                    autoComplete="off"
                    name="passcode"
                    placeholder="123456"
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontFamily: 'monospace',
                      fontSize: 22,
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      let nextOtp = e.target.value
                      if (nextOtp.length < 7) {
                        setOtp(nextOtp)
                      }
                    }}
                    value={otp}
                  />
                </InputWrapper>
                <Button
                  large
                  fill
                  type="submit"
                  intent={Intent.PRIMARY}
                  text="Signin"
                  loading={fetching}
                  disabled={disabledSubmitButton}
                />
                <div style={{ marginTop: 5, textAlign: 'right' }}>
                  {codeResending ? (
                    <span style={{ color: 'rgba(255,255,255,0.7)' }}>
                      Processing...
                    </span>
                  ) : (
                    <ResetLink
                      onClick={() => {
                        setOtp('')
                        resendPasscode()
                      }}>
                      Resend passcode
                    </ResetLink>
                  )}
                </div>
              </animated.div>
            )
          )}
        </form>
      </LoginWrapper>
    </Main>
  )
}

const Main = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding-top: 200px;
  background-image: var(--page-background-image);
`

const LoginWrapper = styled.div`
  width: 350px;
`

const LoginHeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const InputWrapper = styled.div`
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
`
const OTPMessage = styled.p`
  color: white;
`

const ResetLink = styled.span`
  color: white;
  cursor: pointer;
  &:hover {
    color: white;
    text-decoration: underline;
  }
`
