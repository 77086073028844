import React, { useState } from 'react'
import { Router, Redirect } from '@reach/router'
import Login from './components/Login'
import ProductManager from './components/ProductManager'
import UnderConstruction from './components/Dashboard/UnderConstruction'
import Authenticated from './components/Authenticated'
import { ModeProvider } from './modeContext'

function App() {
  const [accessToken, setAccessToken] = useState<null | undefined | string>(
    window.localStorage.getItem('_jX22Dksj&.K')
  )

  return accessToken ? (
    <Router style={{ height: '100%' }} primary={false}>
      <Redirect from="*" to="app/product" noThrow />
      <Authenticated
        path="app"
        token={accessToken ? accessToken : ''}
        removeToken={() => {
          window.localStorage.removeItem('_jX22Dksj&.K')
          setAccessToken('')
        }}>
        <ProductManager path="product" setAccessToken={setAccessToken} />
        <UnderConstruction path="dashboard" setAccessToken={setAccessToken} />
      </Authenticated>
    </Router>
  ) : (
    <Login setAccessToken={setAccessToken} changeMode={() => null} />
  )
}

export default () => (
  <ModeProvider>
    <App />
  </ModeProvider>
)
